import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getDatabase, ref, onValue } from "firebase/database"
import { collection, collectionGroup, query, where, doc, getDoc, startAfter, startAt, setDoc, onSnapshot, updateDoc, endAt, getFirestore, getDocs, orderBy, limit, getCountFromServer, or } from 'firebase/firestore'
import { getStorage, getDownloadURL, uploadBytes, updateMetadata, listAll, list } from "firebase/storage";
import { ref as sRef } from "firebase/storage"
import { getAnalytics, setUserId } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCyWQ26iBtEeVCdU62CnpN3bkfaNk0ZKjY",
    authDomain: "ngen-art.firebaseapp.com",
    projectId: "ngen-art",
    storageBucket: "ngen-art.appspot.com",
    messagingSenderId: "883647117578",
    appId: "1:883647117578:web:01135133dbc1cb447322f3",
    measurementId: "G-Q14R769S1W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const functions = getFunctions(app);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        setUserId(analytics, uid)
    } else {

    }
});

export { app, auth, functions, analytics, startAfter, startAt, onSnapshot, signInAnonymously, endAt, httpsCallable, updateDoc, db, getDocs, setDoc, ref, onValue, or, listAll, collection, query, where, doc, getDoc, storage, getStorage, getDownloadURL, uploadBytes, updateMetadata, connectFunctionsEmulator, sRef, list, orderBy, limit, getCountFromServer, collectionGroup } 